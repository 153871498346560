@tailwind base;
@tailwind components;
@tailwind utilities;

/* Scrollbar personalizado */
.custom-scrollbar::-webkit-scrollbar {
    width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: transparent; /* Fondo del track transparente */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2); /* Fondo oscuro con transparencia */
    border-radius: 10px; /* Bordes redondeados */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.4); /* Mayor opacidad al pasar el mouse */
}

.bg-home-pattern {
    background-image: url("https://enlacewebdesign.com/images/bg/background_002.webp");
    background-size: cover; /* Cambiado de cover a contain */
    background-position: center;
    background-repeat: no-repeat;
}
  
body {
    font-family: 'Montserrat', sans-serif;
}

p {
    font-family: 'Montserrat', sans-serif;
}
  